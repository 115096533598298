import styled, {keyframes} from "styled-components"


export const BLACK = "#000"
export const WHITE = "#fff"
export const BORDERCOLOR = "#D5DDE0"
export const YELLOW = "#f2e10b"
export const DARKGREY = "#686868"
export const BLACKGREY = "#323C46"
export const LIGHTGREY  = "#C4C4C4"
export const GREYWHITE = "#F5F5F5"
export const RED  = "#FF5252"
export const MAINCOLOR = "#0074FF"
export const GREEN = "#4AFF93"


export const shadow600  = "0px 20px 30px rgba(14, 36, 58, 0.1)"
export const coloredShadow = "0 10px 20px -10px rgba(0, 116, 255, 0.5);"




export const InputButtonsGrid = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, auto);
grid-gap: 5vw;
width: 100%;
>*{
  width: 100%;
}`

export const device = {
    mobileSmallPortrait: `(min-width: 300px) and (orientation: portrait)`,
    mobileLargePortrait: `(min-width: 370px) and (orientation: portrait)`,
    mobileLandscape: `(min-width: 370px) and (orientation: landscape)`,
    tabletSmallPortrait: `(min-width: 768px) and (orientation: portrait)`,
    tabletLargePortrait: `(min-width: 1024px) and (orientation: portrait)`,
    laptopSmall: `(min-width: 1024px) and (orientation: landscape)`,
    laptopLarge: `(min-width: 1500px) and (orientation: landscape)`,
    desktop: `(min-width: 2500px) and (orientation: landscape)`
  };







export const Button = styled.button`
padding: 12px 20px;
background-color: ${MAINCOLOR};
color: white;
border: none;
border-radius: 10rem;
font-size: 1rem;
font-weight: 600;
min-height: 42px;
cursor: pointer;
transition:   all 0.3s ease-in-out;
box-shadow: ${coloredShadow};


&.small{
  padding: 8px 16px;
}
&.ghost{
  border: 1px solid ${MAINCOLOR};
  background-color: transparent;
  color: ${MAINCOLOR};
  box-shadow: none;
}
&.text{
  border: none;
  background-color: transparent;
  color: ${MAINCOLOR};
  box-shadow: none;
}

:disabled{
  background-color: ${LIGHTGREY};
  color: ${WHITE};
  cursor: not-allowed;
  box-shadow: none;
  
}
@media ${device.tabletSmallPortrait}{
  padding: 1.2rem 2rem;
}
@media ${device.laptopSmall}{
    padding: 1rem 2rem;

  :hover{
    transform: scale(1.1);
}
}


`




export const InputStyle = styled.input`
  min-height: 4rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 14px;
  font-size: 1rem;
  padding: 10px;
  background: ${GREYWHITE};
  border:none;
  padding-left: 20px;
  ::placeholder{
    color: #9CA9B4;
    font-weight: 500;
  }
 
  :hover{
    border: 3px solid ${BLACKGREY}20;
  }
  
  
 
  
  :focus{
    outline: none;
    border: 3px solid ${MAINCOLOR}50;
    + label{
      transform: translateY(0px);
      font-size: 0.7rem !important;

    }
  }
 `

