import Head from "next/head"
import Menu from '../home/Menu';
import Footer from '../home/Footer';
import { MAINCOLOR } from '../../styles/styles';


const Layout = ({ children }) => <>
<Head>
<meta name="color-scheme" content="light only"/>
<link rel="icon" href="/favicon.ico" />
<link rel="apple-touch-icon" href="/favicon.ico"/>
<meta name="theme-colot" content={MAINCOLOR}/>


</Head>

    <Menu></Menu>
    {children}
    <Footer></Footer>
</>


export default Layout