import React, { useRef } from 'react'
import styled from 'styled-components';
// import translate from '../../i18n/messages/translate';
import Link from 'next/link';
import { BLACKGREY, Button, MAINCOLOR, YELLOW } from '../../styles/styles';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
   
const Badge = styled.div`
width: 150px;
>img{
  height: 50px;
  width:auto;
}`

const Badges = styled.div`
  max-width: 1350px;
  margin:auto;
`


 const Box = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;


`;
   
 const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1350px;
    margin: auto ;
    /* background: red; */
`
   
 const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  >a{
    color: #ffffff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
   
  &:hover {
      color: ${YELLOW};
      transition: 200ms ease-in;
  }
  }
`
   
 const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 
                         minmax(20%, 1fr));
  grid-gap: 20px;
   
  @media (max-width: 1350px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;
   

   
 const Heading = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: bold;
`;
 
const Footer = () => {
    const {t} = useTranslation("common")


   
    return (
      <footer  id="footer" style={{background: MAINCOLOR}}>
        <Box className='sectionPadding'>
    
          <Container>
            <Row >
        
              <Column>
                <Heading>{t("legal agreements")}</Heading>
                <Link href={`/termeni`}>{"Terms and Conditions"}</Link>
                <Link href={`/privacy-policy`}>{"Privacy Policy"}</Link>
                {/* <Link href="/termeni-si-conditii">{"termeni si conditii"}</Link>
              <Link href="/politica-de-confidențialitate">{"politica"}</Link> */}
              </Column>
              <Column>
                <Heading>{t("contact")}</Heading>
                <a href = "mailto: info@beelocals.com">info@beelocals.com</a>
                <a href = "tel: +37369513786">Tel: +37369513786</a>
              </Column>
              <Column>
                <Heading>{t("language")}</Heading>
                <Link href="/" locale="ro">{t("romanian")}</Link>
                <Link href="/" locale="ru">{t("russian")}</Link>
                <Link href="/" locale="en">{t("english")}</Link>
              </Column>
              <Column>
                <Heading>{t("others")}</Heading>
                <Link href={`/blog`}>{t("blog")}</Link>
              </Column>
              {/* <Column>
                <Heading>{"Take Action"}</Heading>
                <Link href="/create"><Button>Create Store</Button></Link>
                <Link href="/logIn"><Button className='text'>Log In</Button></Link>
              </Column> */}
        
            </Row>
          </Container>
          <Badges >
            {/* <Badge>
              <Image style={{position:"static"}} layout='responsive'  src={visa}></Image>
            </Badge> */}
          </Badges>
        </Box>
      </footer>
    );
  };
  export default Footer;