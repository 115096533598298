
export const BLACK = "#000"
export const WHITE = "#fff"
export const BORDERCOLOR = "#D5DDE0"
export const YELLOW = "#f2e10b"
export const DARKGREY = "#686868"
export const BLACKGREY = "#323C46"
export const LIGHTGREY  = "#C4C4C4"
export const GREYWHITE = "#F5F5F5"
export const RED  = "#FF5252"
export const MAINCOLOR = "#0074FF"
export const GREEN = "#4AFF93"